import { render, staticRenderFns } from "./commentItem.vue?vue&type=template&id=bfedf71a&scoped=true"
import script from "./commentItem.vue?vue&type=script&lang=js"
export * from "./commentItem.vue?vue&type=script&lang=js"
import style0 from "./commentItem.vue?vue&type=style&index=0&id=bfedf71a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfedf71a",
  null
  
)

export default component.exports